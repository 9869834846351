.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #fff;
  animation: loading-dot 0.6s ease-in-out infinite;
}

@keyframes loading-dot {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
}

.loading-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.percent {
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
